import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopUpSize } from 'common-pop-up';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { ErrorFormService, ErrorHttpService, ErrorsMap, IErrorMessage } from 'repository';

@Component({
  selector: 'lib-login-forgot-password',
  templateUrl: './login-forgot-password.component.html',
  styleUrls: ['./login-forgot-password.component.scss'],
})
export class LoginForgotPasswordComponent {
  form: FormGroup;
  errors: ErrorsMap<string> = {};
  readonly errorMessages: ErrorsMap<IErrorMessage> = {
    email: {
      required: 'Email is required',
    },
  };

  @Input() show: boolean;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showInfoSendMail: EventEmitter<boolean> = new EventEmitter<boolean>();

  PopUpSize = PopUpSize;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _forgotPasswordService: ForgotPasswordService,
    private readonly _errorFormService: ErrorFormService,
    private readonly _errorHttpService: ErrorHttpService,
  ) {
    this.show = false;
    this.form = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  close(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  ngSubmit(): void {
    const form = this.form;

    this.errors = this._errorFormService.verifyError(form, this.errorMessages);

    if (form.invalid) return;

    this._forgotPasswordService.sendMailForgotPassword(form.value).subscribe(
      (res) => {
        this.showInfoSendMail.emit(true);
        this.close();
      },
      (error) => this._errorHttpService.showMessage(error)
    );
  }
}
