import { Component, OnInit } from '@angular/core';
import {
  ErrorFormService,
  ErrorsMap,
  IErrorMessage,
  IExistAffiliate,
  NotificationService,
  TypeMessage,
} from 'repository';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserCabinetService } from './../../lib/user-cabinet.service';
import { MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'lib-add-new-affiliate-code',
  templateUrl: './add-new-affiliate-code.component.html',
  styleUrls: ['./add-new-affiliate-code.component.scss'],
})
export class AddNewAffiliateCodeComponent implements OnInit {
  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    AffiliateCode: {
      required: 'Affiliate code is required',
      codeIsExist:
        'This code is already taken, please insert a different code.',
      maxlength: 'Max length 30 symbol',
      pattern: 'Can contain only uppercase letters and numbers',
    },
    acceptAffiliateTermsConditions: {
      required: 'Please accept Affiliate Terms & Conditions',
    },
  };

  form: FormGroup;
  public showLoader = false;

  public errors: ErrorsMap<string> = {};
  public affiliateErrorCode = false;

  constructor(
    private readonly _errorFormService: ErrorFormService,
    private readonly _userCabinetService: UserCabinetService,
    private readonly notification: NotificationService,
    private readonly _dialogRef: MatDialogRef<AddNewAffiliateCodeComponent>,
    private readonly _formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.buildForm();
  }

  public buildForm = (): void => {
    this.form = this._formBuilder.group({
      AffiliateCode: [
        null,
        [
          Validators.required,
          Validators.pattern('[A-Z0-9]*$'),
          Validators.maxLength(30),
        ],
      ],
      acceptAffiliateTermsConditions: [
        false,
        [Validators.required, this._checkAcceptAffiliateTermsConditions],
      ],
    });

    this.form
      .get('AffiliateCode')
      .setAsyncValidators(this.validateAffiliateCode);
  };

  public addNewCode() {
    const form: FormGroup = this.form;

    this.errors = this._errorFormService.verifyError(form, this.errorMessages);

    if (this.affiliateErrorCode) {
      this.errors.AffiliateCode = this.errorMessages.AffiliateCode.codeIsExist;
    }

    if (!form.value.acceptAffiliateTermsConditions) {
      this.errors.acceptAffiliateTermsConditions = this.errorMessages.acceptAffiliateTermsConditions.required;
    }

    if (
      this.affiliateErrorCode ||
      form.invalid ||
      !form.value.acceptAffiliateTermsConditions
    ) {
      return;
    }

    this.showLoader = true;

    this._userCabinetService
      .createNewCode(this.form.get('AffiliateCode').value)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.showLoader = false;
          this.notification.showBlankNotification(
            'Affiliate code successfully created',
            TypeMessage.SUCCESS
          );
          this._dialogRef.close();
        },
        (error) => {
          switch (error.error.errorCode) {
            case 3021:
              this.notification.showBlankNotification(
                'You cannot create more than 10 referral codes. ' +
                  'Please contact support if you have any questions',
                TypeMessage.ERROR
              );
              break;
            default:
              this.notification.showBlankNotification(
                'Affiliate code already exist',
                TypeMessage.ERROR
              );
              break;
          }
          this._dialogRef.close();

          this.showLoader = false;
        }
      );
  }

  private readonly validateAffiliateCode = (control: AbstractControl) => {
    return this._userCabinetService.getReferral(control.value).pipe(
      untilDestroyed(this),
      catchError(() => of({} as IExistAffiliate)),
      tap((res) => {
        this.affiliateErrorCode = !!res?.id;
      })
    );
  };

  private readonly _checkAcceptAffiliateTermsConditions = (
    control: FormControl
  ): boolean | { notSame: boolean } => {
    return control.value ? null : { notSame: true };
  };
}
