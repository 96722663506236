import { NgModule } from '@angular/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { FormForLoginComponent } from './form-for-login/form-for-login.component';
import { LoginForgotPasswordComponent } from './login-forgot-password/login-forgot-password.component';
import { LoginInfoSendMailComponent } from './login-info-send-mail/login-info-send-mail.component';
import { LoginResetPasswordComponent } from './login-reset-password/login-reset-password.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponentsModule } from 'projects/base-components/src/public-api';
import { CommonModule } from '@angular/common';
import { LoginPasswordSuccessfulUpdateComponent } from './login-password-successful-update/login-password-successful-update.component';
import { SignUpModule } from 'projects/sign-up/src/public-api';
import { RouterModule } from '@angular/router';
import { CommonPopUpModule } from 'common-pop-up';
import { ModalWindowModule } from 'modal-window';
import { Translate } from 'localization';

@NgModule({
  declarations: [
    LoginComponent,
    FormForLoginComponent,
    LoginForgotPasswordComponent,
    LoginInfoSendMailComponent,
    LoginResetPasswordComponent,
    LoginPasswordSuccessfulUpdateComponent,
  ],
  imports: [
    CommonModule,
    NzAvatarModule,
    NzDropDownModule,
    NzFormModule,
    NzPaginationModule,
    NzTabsModule,
    CommonPopUpModule,
    FormsModule,
    ReactiveFormsModule,
    BaseComponentsModule,
    SignUpModule,
    RouterModule,
    ModalWindowModule,
    Translate,
  ],
  exports: [
    LoginComponent,
    LoginResetPasswordComponent,
    LoginPasswordSuccessfulUpdateComponent,
  ],
})
export class LoginModule {}
