import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User } from 'user';
import { ModalService } from '@app/_modal';
import { IUserCabinetLinks } from '../../interfaces';
import { UserCabinetLinks } from '../../data';
import { UserCabinetService } from '../user-cabinet.service';
import { MatDialog } from '@angular/material/dialog';
import { AddNewAffiliateCodeComponent } from '../../dialog/add-new-affilfiate-code/add-new-affiliate-code.component';
@Component({
  selector: 'lib-header-user-info',
  templateUrl: './header-user-info.component.html',
  styleUrls: ['./header-user-info.component.scss'],
})
export class HeaderUserInfoComponent implements OnInit {
  public username = '';
  public isShowMenu = false;
  public MenuLinks: IUserCabinetLinks[] = UserCabinetLinks;

  @ViewChild('toggleButton')
  public toggleButton: ElementRef;

  @ViewChild('menu')
  public menu: ElementRef;

  constructor(
    public readonly user: User,
    public readonly modalService: ModalService,
    private readonly userCabinetService: UserCabinetService,
    private readonly dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.username = this.user.fullName.trim() ?? 'Username';
  }

  public loadImageError(): void {
    // TODO: would be better to place in other component
    this.user.setDefaultPhoto();
  }

  public signOut(): void {
    this.userCabinetService.signOut();
  }

  public rotateIcon($event: boolean) {
    this.isShowMenu = $event;
  }

  // tslint:disable-next-line:no-any
  public addNewAffiliateCode($event: any) {
    $event.preventDefault();
    this.dialog.open(AddNewAffiliateCodeComponent, {
      maxHeight: '95vh',
      minWidth: '25vw',
      maxWidth: '50vw',
    });
  }
}
