<lib-modal-window [isVisible]="show" (isClose)="close()">
  <div class="logo-box">
    <lib-takeprofit-title [desktopOnly]="true"></lib-takeprofit-title>
  </div>

  <h1 id="title" class="primary-block-by-center">
    {{ 'forgot-password' | translate }}
  </h1>
  <h3 id="text" class="primary-block-by-center">
    {{ 'enter-the-email' | translate }}
  </h3>

  <form
    class="primary-form forgot-password-form"
    [formGroup]="form"
    (ngSubmit)="ngSubmit()"
  >
    <nz-form-item>
      <nz-form-control [nzErrorTip]="errors.email">
        <input
          type="email"
          name="email"
          id="email"
          class="primary-input"
          formControlName="email"
          placeholder="Email"
        />
      </nz-form-control>
    </nz-form-item>

    <div id="buttons-nav">
      <button id="confirm" class="primary-btn">
        {{ 'confirm' | translate }}
      </button>
      <button id="back" type="button" class="primary-btn" (click)="close()">
        {{ 'back' | translate }}
      </button>
    </div>
  </form>
  <p class="text-in-center login-box">
    <span class="login">{{ 'remember-your-password' | translate }} </span
    ><a class="login-link" (click)="close()">{{ 'login_up' | translate }}</a>
  </p>
</lib-modal-window>
