import { Injectable } from '@angular/core';
import { Placement } from '@app/enums/norification-placement';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TypeMessage } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private readonly notification: NzNotificationService) {}

  showBlankNotification(
    title: string,
    styleType: TypeMessage,
    nzPlacement: Placement = Placement.BOTTOM_RIGHT
  ): void {
    const style = styleType + '-popup';
    this.notification.blank(title, '', {
      nzPlacement,
      nzClass: style,
    });
  }
}
