import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class BaseComponentsService {
  constructor(private readonly _http: HttpClient) {}
  // TODO: fix any
  // tslint:disable-next-line:no-any
  sendContactUs(body: { name: string; email: string; message: string; }): Observable<any> {
    return this._http.post(
      `${environment.accountsUrl}/api/Communication/contact-us`,
      body
    );
  }
}
