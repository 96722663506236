import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IBaseStep } from '../base-step/base-step';
import { SignUpService } from '../sign-up.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import {
  ErrorFormService,
  ErrorHttpService,
  ErrorsMap,
  IErrorMessage,
  IExistAffiliate,
  NotificationService,
  TwilioService,
  TypeMessage,
} from 'repository';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Component({
  selector: 'lib-form-for-sign-up',
  templateUrl: './form-for-sign-up.component.html',
  styleUrls: ['./form-for-sign-up.component.scss'],
  providers: [TwilioService],
})
@UntilDestroy()
export class FormForSignUpComponent implements OnInit, IBaseStep {
  public readonly passwordRequirements = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9_]).{8,}$/;
  public readonly excludeEmails = /^(?!.*@(ezztt|icznn|vjuum|laafd|txcct)\.com$).*/;
  public readonly inputRequirements =
    '^[A-Za-z0-9\\s!#$%^&*()_+=\\-~\\][{}|\\/.?><]*$';
  public readonly inputRequirementsName = '^[A-Za-z\\s]*$';

  public errors: ErrorsMap<string> = {};
  public showPassword: boolean;
  public showConfirmPassword: boolean;
  public showError: boolean;
  public submited: boolean;
  public showNext = false;

  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    email: {
      required: 'Incorrect e-mail address',
      email: 'Incorrect e-mail address',
      pattern: 'This email is not supported.',
    },
    password: {
      required: 'Password must contain between 6 and 12 letters or numbers',
    },
    phoneNumber: {
      required: 'Phone is required',
      exists: 'Phone number already exists',
      min: 'Phone must be at least 10 numbers.',
    },
    confirmPassword: {
      required: 'Password don`t match',
    },
    firstName: {
      required: 'This field is required',
      pattern: 'Only latin letters are allowed',
      minlength: 'Min length 2 letters',
      maxlength: 'Max length 64 letters',
      oneLetter: 'Names that consist of one letter are not allowed',
    },
    lastName: {
      minlength: 'Min length 2 letters',
      maxlength: 'Max length 64 letters',
      required: 'Filled is required',
      pattern: 'Only latin letters',
      oneLetter: 'Names that consist of one letter are not allowed',
    },
    acceptTermsOfService: {
      required: 'Please accept Terms Of Service',
    },
  };

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  public form: FormGroup;

  @Input()
  public set isShowResendConfirmEmail(isShowResendConfirmEmail: boolean) {
    if (this.isShowResendConfirmEmail === isShowResendConfirmEmail) {
      return;
    }

    this._isShowResendConfirmEmail = isShowResendConfirmEmail;
    this.isShowResendConfirmEmailChange.emit(isShowResendConfirmEmail);
  }

  public get isShowResendConfirmEmail(): boolean {
    return this._isShowResendConfirmEmail;
  }

  @Input()
  public set isLoading(isLoading: boolean) {
    if (isLoading !== this._isLoading) {
      this.isLoadingChange.emit(isLoading);
    }

    this._isLoading = isLoading;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  @Output()
  public readonly isLoadingChange = new EventEmitter<boolean>();

  @Output()
  public readonly isShowResendConfirmEmailChange = new EventEmitter<boolean>();

  @Output()
  public readonly next = new EventEmitter<boolean>();

  @Output()
  public readonly showLogin = new EventEmitter<boolean>();

  public readonly environmentCaptchaToken = environment.captchaToken;

  private referralCode?: string;
  private _isLoading = false;
  private _reCaptchaToken: null | string = null;

  private _isShowResendConfirmEmail = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly _formBuilder: FormBuilder,
    private readonly _signUpService: SignUpService,
    private readonly _twilioService: TwilioService,
    private readonly _errorFormService: ErrorFormService,
    private readonly _notificationService: NotificationService,
    private readonly _errorHttpService: ErrorHttpService,
    private readonly zone: NgZone
  ) {
    this.showPassword = false;
    this.showConfirmPassword = false;
    this.showError = false;
    this.submited = false;
  }

  public ngOnInit(): void {
    this.buildForm();

    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.referralCode = params.referralCode;
      });
  }

  public showResendConfirmEmail(): void {
    this.isShowResendConfirmEmail = true;
  }

  public buildForm = (): void => {
    this.form = this._formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(this.excludeEmails),
        ],
      ],
      phoneNumber: [
        '',
        [Validators.required, Validators.minLength(10)],
        [this.existPhone()],
      ],
      password: [null, [Validators.required, this._validatePassword]],
      confirmPassword: [null, [Validators.required, this._checkPassword]],
      phoneNumberConfirmed: [false],
      firstName: [
        null,
        [
          Validators.required,
          Validators.pattern(this.inputRequirementsName),
          Validators.minLength(2),
          Validators.maxLength(64),
          this._checkWordWithOneLetter,
        ],
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.pattern(this.inputRequirementsName),
          Validators.minLength(2),
          Validators.maxLength(64),
          this._checkWordWithOneLetter,
        ],
      ],
      acceptTermsOfService: [
        null,
        [Validators.required, this._checkAcceptTermsOfService],
      ],
    });
  };

  public get() {
    return this.form.value;
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  public set(value: any): void {
    this.form.reset(value);
  }

  public submit() {
    return true;
  }

  public passwordIconUrl = (show: boolean): string =>
    `assets/password_kit/${show ? 'show' : 'hide'}.svg`;

  public handlePasswordType = (show: boolean): string =>
    show ? 'text' : 'password';

  resolved($event: string) {
    this._reCaptchaToken = $event;
  }

  public handleSubmit(): void {
    const form: FormGroup = this.form;

    if (this.passwordRequirements.test(this.form.value.password) === false) {
      this.errors.password = 'Password did not pass requirements';
      this.showError = true;
    } else {
      this.showError = false;
    }

    this.submited = true;

    if (form.invalid) {
      this.isLoading = false;

      if (this.form.get('phoneNumber').hasError('exists')) {
        this.errors.phoneNumber = 'Phone number already exists';

        return;
      }

      this.errors = this._errorFormService.verifyError(
        form,
        this.errorMessages
      );

      this.isLoading = false;

      return;
    }

    const value = {
      ...form.value,
      lastName: form.value.lastName.trim().replace(/[_-]/g, ' '),
      firstName: form.value.firstName.trim().replace(/[_-]/g, ' '),
    };
    value.phoneNumber = form.value?.phoneNumber?.e164Number;

    if (value.password !== value.confirmPassword) {
      this.errors.confirmPassword =
        'Confirm password has to equal to the password';
    }

    if (!value.acceptTermsOfService) {
      this.errors.acceptTermsOfService = this.errorMessages.acceptTermsOfService.required;
    }

    if (this.referralCode) {
      value.referralCode = this.referralCode;
    }

    if (!this._reCaptchaToken) {
      this._notificationService.showBlankNotification(
        'Captcha must be solved',
        TypeMessage.ERROR
      );

      return;
    }

    this.isLoading = true;
    this._signUpService
      .checkCaptcha({ token: this._reCaptchaToken })
      .pipe(
        switchMap((res) => {
          if (!res.isSuccess) {
            this._notificationService.showBlankNotification(
              'Captcha not valid',
              TypeMessage.ERROR
            );

            return;
          }

          return this._signUpService.createUser(value);
        })
      )
      .subscribe(
        (res) => {
          this.zone.run(() => {
            this.next.emit(true);
            this.isLoading = false;
          });
        },
        (error) => {
          this._errorHttpService.showMessage(error);
          this.isLoading = false;
        }
      );
  }
  // tslint:disable-next-line:no-any
  errored($event: any) {
    console.warn(`reCAPTCHA error encountered`, $event);
  }

  public login(): void {
    this.showLogin.emit(true);
  }

  private readonly _validatePassword = (
    control: FormGroup
  ): boolean | { notValid: boolean } => {
    const password: string = control.value;

    return this.passwordRequirements.test(password) ? null : { notValid: true };
  };

  private readonly _checkPassword = (
    control: FormControl
  ): boolean | { notSame: boolean } => {
    if (control?.value !== this.form?.controls?.password?.value) {
      return { notSame: true };
    }
  };

  private readonly _checkWordWithOneLetter = (
    control: FormControl
  ): boolean | { oneLetter: boolean } => {
    const value = control?.value;
    if (value) {
      const array = value.split(' ') ?? [];
      const wordArray = array.filter((item: string) => item.length === 1);

      return wordArray.length > 0 ? { oneLetter: control.value } : null;
    }

    return null;
  };

  private readonly _checkAcceptTermsOfService = (
    control: FormControl
  ): boolean | { notSame: boolean } => {
    return this.form?.controls?.acceptTermsOfService?.value
      ? null
      : { notSame: true };
  };

  private existPhone(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this._twilioService
        .existPhone(encodeURIComponent(control.value.e164Number))
        .pipe(
          untilDestroyed(this),
          catchError(() => of({} as IExistAffiliate)),
          map((res) => {
            return !res ? null : { exists: 'reerer' };
          })
        );
    };
  }
}
