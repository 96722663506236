<lib-modal-window [isVisible]="show" (isClose)="close()">
  <img class="letter-icon" src="./assets/logos/mail.svg" alt="" />
  <h3 id="title" class="text">{{ 'recovery-link-sent' | translate }}</h3>
  <br />
  <p id="time" class="text">
    {{ 'an-email-has-been-sent' | translate: { blurred: blurredEmail } }}
  </p>

  <div class="div-button">
    <button class="primary-btn" (click)="close()">
      {{ 'log-in' | translate }}
    </button>
  </div>
</lib-modal-window>
