<router-outlet></router-outlet>
<ng-container *ngIf="showLoader | async">
  <div class="loader-block">
    <ul class="loader">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</ng-container>
