import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../../src/services/header-navigation-service';

@Component({
  selector: 'lib-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  constructor(private readonly _navigation: NavigationService) {}

  back(): void {
    this._navigation.back();
  }
}
