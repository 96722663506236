import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { PopUpSize } from 'common-pop-up';
import { IBaseStep, IPaymentData, ITradingData } from './interfaces';
import { ChooseAccountSizeComponent } from './choose-account-size/choose-account-size.component';
import { ConfirmSubscriptionComponent } from './confirm-subscription/confirm-subscription.component';
import { CreateSubscriptionService } from './create-subscription.service';
import { PaymentCreditCardComponent } from './payment-credit-card/payment-credit-card.component';
import { SuccessfullyComponent } from './successfully/successfully.component';
import { User } from 'user';
import {
  ErrorCode,
  Payment,
  SuccessfulMessage,
  TypeOfCreateSubscription,
} from './enum';
import {
  ErrorHttpService,
  NotificationService,
  PaymentMethod,
  TypeMessage,
} from 'repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { PaymentCreditCardNmiComponent } from './payment-credit-card-nmi/payment-credit-card-nmi.component';
import { PaymentCreditCardNuveiComponent } from './payment-credit-card-nuvei/payment-credit-card-nuvei.component';

@UntilDestroy()
@Component({
  selector: 'lib-create-subscription',
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.scss'],
})
export class CreateSubscriptionComponent implements OnChanges, OnDestroy {
  get isScroll(): boolean {
    const tab = this.steps.get(this.tabIndex);

    return (
      tab instanceof ChooseAccountSizeComponent ||
      tab instanceof ConfirmSubscriptionComponent
    );
  }

  get isSuccessMessage(): boolean {
    const tab = this.steps.get(this.tabIndex);

    return tab instanceof SuccessfullyComponent;
  }

  get isNotSuccessful(): boolean {
    const tab = this.steps.get(this.tabIndex);

    return !(tab instanceof SuccessfullyComponent);
  }

  get isConfirmSubscription() {
    const tab = this.steps.get(this.tabIndex);

    return tab instanceof ConfirmSubscriptionComponent && this.tabIndex !== 0;
  }

  @ViewChildren('step')
  public steps: QueryList<IBaseStep> = new QueryList();

  @Input() type: TypeOfCreateSubscription;
  @Input() subscriptionsId: number;
  @Input() accountType: number;
  @Input() resetAmount: number;
  @Input() tradingData: ITradingData[];
  @Input() accountSize: string;
  @Input() monthlyPrice: number;
  @Input() isGoToPro = false;
  @Input() show = false;

  @Output()
  public readonly showChange = new EventEmitter<boolean>();

  @Output()
  public readonly isGoToProChange = new EventEmitter<boolean>();

  @Output()
  public readonly isUpdate = new EventEmitter<boolean>();

  tabIndex = 0;

  public readonly TypeOfCreateSubscription = TypeOfCreateSubscription;
  public readonly SuccessfulMessage = SuccessfulMessage;
  public readonly PaymentMethod = PaymentMethod;
  public readonly PopUpSize = PopUpSize;

  paymentMethod: PaymentMethod | null = null;

  isShowTabset = true;
  update: boolean;
  updateStripe = false;
  updateNmi = false;
  updateNuvei = false;
  setDefaultPayment = false;
  isLoading = false;
  isShowConfirm = false;
  isShowCreditsConfirm = false;
  acceptTerms: FormControl = new FormControl(false, Validators.required);
  terms: FormControl = new FormControl(false, Validators.required);
  classAction: FormControl = new FormControl(false, Validators.required);
  theseSteps: FormControl = new FormControl(false, Validators.required);

  successfulMessage = SuccessfulMessage.CREATE_NEW_SUBSCRIPTION;

  subscription: IPaymentData;

  promocodeId?: number;
  price = 0;
  promoAmount = 0;

  isPaid = false;

  private _clientSecret: string;

  constructor(
    private readonly _createSubscriptionService: CreateSubscriptionService,
    private readonly _user: User,
    private readonly zone: NgZone,
    private readonly _errorHttpService: ErrorHttpService,
    private readonly _notificationService: NotificationService,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {}

  updateAfterClose(): void {
    this.isUpdate.emit(true);
    this.tabIndex = 0;
    this.subscription = null;
    this.close();
    this._user
      .updateUserInfo()
      .pipe(untilDestroyed(this))
      .subscribe(() => null);
  }

  ngOnChanges(): void {
    if (this.type === TypeOfCreateSubscription.RESET_ACCOUNT) {
      this.successfulMessage = SuccessfulMessage.RESET_ACCOUNT;

      if (this.accountType === 1) {
        this.successfulMessage = SuccessfulMessage.RESET_PRO_ACCOUNT;
      }
    }

    if (this.type === TypeOfCreateSubscription.RESET_PRO_ACCOUNT) {
      this.successfulMessage = SuccessfulMessage.RESET_PRO_ACCOUNT;
    }

    if (this.type === TypeOfCreateSubscription.CHANGE_PAYMENT) {
      this.successfulMessage = SuccessfulMessage.CHANGE_PAYMENT;
    }

    if (this.type === TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
      this.successfulMessage = SuccessfulMessage.AGREEMENT_PAYMENT;
      this.isShowTabset = false;
    }

    if (this.type === TypeOfCreateSubscription.CHANGE_PAYMENT) {
      this.successfulMessage = SuccessfulMessage.CHANGE_PAYMENT;
    }

    this.update = !this.update;
  }

  goToPay(value: boolean): void {
    if (!value) {
      this.show = false;
      this.isGoToPro = false;
      this.isGoToProChange.emit(this.isGoToPro);
      this.showChange.emit(false);

      return;
    }

    this.isShowTabset = true;
    this.show = true;
  }

  close(): void {
    this.show = false;
    this.isGoToPro = false;
    this.successfulMessage = SuccessfulMessage.CREATE_NEW_SUBSCRIPTION;
    this.isGoToProChange.emit(this.isGoToPro);
    this.showChange.emit(false);

    const tab = this.steps.get(this.tabIndex);

    if (!(tab instanceof PaymentCreditCardComponent)) {
      this.updateStripe = false;
      this.updateNmi = false;
    }

    if (tab instanceof SuccessfullyComponent) {
      this.isUpdate.emit(true);

      if (
        this.successfulMessage === SuccessfulMessage.CREATE_NEW_SUBSCRIPTION
      ) {
        setTimeout(() => {
          this._notificationService.showBlankNotification(
            "If your subscription doesn't appear in the list, check back in a few minutes.",
            TypeMessage.SUCCESS
          );
        }, 7000);
      }
    }
  }

  changePrice(value: number): void {
    this.price = value;
  }

  changePromoAmount(value: number): void {
    this.promoAmount = value;
  }

  choosePayment(value: PaymentMethod): void {
    this.paymentMethod = value;
    this._verifyPaymentMethods();
  }

  setPromocode(value: number): void {
    this.promocodeId = value;
  }

  emitGotToPro(value: boolean): void {
    this.isGoToPro = value;
    this.isShowTabset = true;
    this.isGoToProChange.emit(this.isGoToPro);
  }

  emitShow(value: boolean): void {
    this.show = value;
    this.showChange.emit(this.show);
  }

  next(): boolean {
    if (
      TypeOfCreateSubscription.CREATE_SUBSCRIPTION === this.type &&
      +this.tabIndex === 2
    ) {
      if (!this.acceptTerms.value) {
        const error: HttpErrorResponse = {
          error: undefined,
          headers: undefined,
          message: '',
          name: 'HttpErrorResponse',
          ok: false,
          statusText: '',
          type: undefined,
          url: '',
          status: 40004,
        };
        this._errorHttpService.showMessage(error);

        return false;
      }

      if (!this.terms.value) {
        const error: HttpErrorResponse = {
          error: undefined,
          headers: undefined,
          message: '',
          name: 'HttpErrorResponse',
          ok: false,
          statusText: '',
          type: undefined,
          url: '',
          status: 40006,
        };
        this._errorHttpService.showMessage(error);

        return false;
      }

      if (!this.classAction.value) {
        const error: HttpErrorResponse = {
          error: undefined,
          headers: undefined,
          message: '',
          name: 'HttpErrorResponse',
          ok: false,
          statusText: '',
          type: undefined,
          url: '',
          status: 40009,
        };
        this._errorHttpService.showMessage(error);

        return false;
      }

      if (!this.theseSteps.value) {
        const error: HttpErrorResponse = {
          error: undefined,
          headers: undefined,
          message: '',
          name: 'HttpErrorResponse',
          ok: false,
          statusText: '',
          type: undefined,
          url: '',
          status: 40008,
        };
        this._errorHttpService.showMessage(error);

        return false;
      }

      if (!this.paymentMethod) {
        const error: HttpErrorResponse = {
          error: undefined,
          headers: undefined,
          message: '',
          name: 'HttpErrorResponse',
          ok: false,
          statusText: '',
          type: undefined,
          url: '',
          status: 40005,
        };
        this._errorHttpService.showMessage(error);

        return false;
      }
    }

    if (
      !this.paymentMethod &&
      TypeOfCreateSubscription.CREATE_SUBSCRIPTION !== this.type
    ) {
      const error: HttpErrorResponse = {
        error: undefined,
        headers: undefined,
        message: '',
        name: 'HttpErrorResponse',
        ok: false,
        statusText: '',
        type: undefined,
        url: '',
        status: 40005,
      };
      this._errorHttpService.showMessage(error);

      return false;
    }

    const step = this.steps.get(this.tabIndex);
    if (!step.submit()) return;

    if (step instanceof ConfirmSubscriptionComponent) {
      switch (this.paymentMethod) {
        case PaymentMethod.CREDIT_CARD:
          this.updateStripe = true;
          break;

        case PaymentMethod.CREDIT_CARD_NMI:
          this.updateNmi = true;

          break;
        case PaymentMethod.CREDIT_CARD_NUVEI:
          this.updateNuvei = true;

          break;
        case PaymentMethod.PAY_PAL:
          this._pay();

          return;
        case PaymentMethod.WALLET:
          this.isShowConfirm = !this.isShowConfirm;

          return;
        case PaymentMethod.USER_CREDITS:
          this.isShowCreditsConfirm = !this.isShowCreditsConfirm;

          return;
        default:
          break;
      }
    }

    this.nextStep();
  }

  nextStep(): void {
    this.zone.run(() => {
      switch (this.paymentMethod) {
        case PaymentMethod.CREDIT_CARD_NUVEI:
          this._createSubscriptionService.startNuvei.next(true);

          break;
        default:
          break;
      }
      this.setDefaultPayment = false;
      this.tabIndex++;
      this.update = !this.update;
      if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        this.isLoading = false;
      }
      this._verifyPaymentMethods();
    });
  }

  prev(): void {
    this.choosePayment(null);
    this.setDefaultPayment = true;

    this.tabIndex--;
    this.update = !this.update;
  }

  // Pay with wallet after click button yes in modal

  payWallet(): void {
    this.isLoading = true;
    switch (this.type) {
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this._createSubscribeByWallet();

        break;
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
      case TypeOfCreateSubscription.RESET_ACCOUNT:
        this._createSubscriptionService.resetAccount(
          PaymentMethod.WALLET,
          this.price,
          this.promocodeId,
          this.subscriptionsId,
          (data) => {
            this._payByWallet(data);
          }
        );
        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this._createSubscriptionService.changePaymentMethod(
          Payment.WALLET,
          this.subscriptionsId,
          (data) => {
            this._payByWallet(data);
          }
        );

        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this._createSubscriptionService.payAgreement(
          PaymentMethod.WALLET,
          this.promocodeId,
          this.subscriptionsId,
          (data) => {
            this._payByWallet(data);
          }
        );

        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.tabIndex = 0;
  }

  // Pay with credits after click button yes in modal

  payCredits(): void {
    this.isLoading = true;
    switch (this.type) {
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this._createSubscribeByCredits();

        break;
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
      case TypeOfCreateSubscription.RESET_ACCOUNT:
        this._createSubscriptionService.resetAccount(
          PaymentMethod.USER_CREDITS,
          this.price,
          this.promocodeId,
          this.subscriptionsId,
          (data) => {
            this._payByCredits(data);
          }
        );
        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this._createSubscriptionService.changePaymentMethod(
          Payment.CreditBank,
          this.subscriptionsId,
          (data) => {
            this._payByCredits(data);
          }
        );

        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this._createSubscriptionService.payAgreement(
          PaymentMethod.USER_CREDITS,
          this.promocodeId,
          this.subscriptionsId,
          (data) => {
            this._payByWallet(data);
          }
        );

        break;
      default:
        break;
    }
  }

  private _pay(): void {
    if (this._clientSecret) {
      if (isPlatformBrowser(this._platformId)) {
        window.open(this._clientSecret, '_blank');
      }

      return;
    }

    this.isLoading = true;

    switch (this.type) {
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this._createSubscribeByPayPal();

        break;
      case TypeOfCreateSubscription.RESET_ACCOUNT:
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
        this._createSubscriptionService.resetAccount(
          PaymentMethod.PAY_PAL,
          this.price,
          this.promocodeId,
          this.subscriptionsId,
          (data) => {
            this._payByPayPal(data);
          }
        );
        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this._createSubscriptionService.changePaymentMethod(
          Payment.PAY_PAL,
          this.subscriptionsId,
          (data) => {
            this._payByPayPal(data);
          }
        );

        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this._createSubscriptionService.payAgreement(
          PaymentMethod.PAY_PAL,
          this.promocodeId,
          this.subscriptionsId,
          (data) => {
            this._payByPayPal(data);
          }
        );

        break;
      default:
        break;
    }
  }

  private _createSubscribeByWallet(): void {
    this.isLoading = true;

    if (this.subscription) {
      this._payByWallet(this.subscription);
      if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        this.isLoading = false;
      }

      this.successfulMessage = SuccessfulMessage.CREATE_NEW_SUBSCRIPTION;
      this.isPaid = true;

      return;
    }

    this._createSubscriptionService.createSubscribe(
      PaymentMethod.WALLET,
      this.promocodeId,
      (data) => {
        this.subscription = data;
        this._payByWallet(data);
      }
    );
  }

  private _createSubscribeByCredits(): void {
    this.isLoading = true;

    if (this.subscription) {
      this._payByCredits(this.subscription);
      if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        this.isLoading = false;
      }

      this.successfulMessage = SuccessfulMessage.CREATE_NEW_SUBSCRIPTION;
      this.isPaid = true;

      return;
    }

    this._createSubscriptionService.createSubscribe(
      PaymentMethod.USER_CREDITS,
      this.promocodeId,
      (data) => {
        this.subscription = data;
        this._payByCredits(data);
      }
    );
  }

  private _payByWallet(data?: IPaymentData): void {
    if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
      this.isLoading = false;
    }

    switch (this.type) {
      case TypeOfCreateSubscription.RESET_ACCOUNT:
        this.successfulMessage = SuccessfulMessage.RESET_ACCOUNT;
        break;
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this.successfulMessage = SuccessfulMessage.CREATE_NEW_SUBSCRIPTION;
        break;
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
        this.successfulMessage = SuccessfulMessage.RESET_PRO_ACCOUNT;
        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this.successfulMessage = SuccessfulMessage.AGREEMENT_PAYMENT;
        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this.successfulMessage = SuccessfulMessage.CHANGE_PAYMENT;
        break;

      default:
        this.successfulMessage = SuccessfulMessage.DEPOSIT_IS_PAID;
        break;
    }

    if (!data) {
      return;
    }

    this.nextStep();
  }

  private _payByCredits(data?: IPaymentData): void {
    if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
      this.isLoading = false;
    }

    switch (this.type) {
      case TypeOfCreateSubscription.RESET_ACCOUNT:
        this.successfulMessage = SuccessfulMessage.RESET_ACCOUNT;
        break;
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this.successfulMessage = SuccessfulMessage.CREATE_NEW_SUBSCRIPTION;
        break;
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
        this.successfulMessage = SuccessfulMessage.RESET_PRO_ACCOUNT;
        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this.successfulMessage = SuccessfulMessage.AGREEMENT_PAYMENT;
        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this.successfulMessage = SuccessfulMessage.CHANGE_PAYMENT;
        break;

      default:
        this.successfulMessage = SuccessfulMessage.DEPOSIT_IS_PAID;
        break;
    }

    if (!data) {
      return;
    }

    this.nextStep();
  }

  private _createSubscribeByPayPal(): void {
    this.isLoading = true;

    if (this.subscription) {
      this._payByPayPal(this.subscription);
      if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        this.isLoading = false;
      }

      this.successfulMessage = SuccessfulMessage.DEPOSIT_IS_PAID;

      return;
    }

    this._createSubscriptionService.createSubscribe(
      PaymentMethod.PAY_PAL,
      this.promocodeId,
      (data) => {
        this.subscription = data;
        this._payByPayPal(data);
      }
    );
  }

  private _payByPayPal(data: IPaymentData): void {
    const errorCodeDoublePayment = ErrorCode.ERROR_CODE_DOUBLE_PAYMENT;

    if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
      this.isLoading = false;
    }

    if (this.type === TypeOfCreateSubscription.RESET_ACCOUNT) {
      this.successfulMessage = SuccessfulMessage.RESET_ACCOUNT;
    } else if (this.type === TypeOfCreateSubscription.RESET_PRO_ACCOUNT) {
      this.successfulMessage = SuccessfulMessage.RESET_PRO_ACCOUNT;
    } else {
      this.successfulMessage = SuccessfulMessage.DEPOSIT_IS_PAID;
    }

    if (data.errorDoublePayment === errorCodeDoublePayment) {
      this.successfulMessage = SuccessfulMessage.ERROR_DOUBLE_PAYMENT;
    } else {
      this._clientSecret = data?.clientSecret;
      if (isPlatformBrowser(this._platformId)) {
        window.open(data?.clientSecret, '_blank');
      }
    }
    this.nextStep();
  }

  private _verifyPaymentMethods(): void {
    this.isLoading = false;
    const tab = this.steps.get(this.tabIndex);
    if (
      tab instanceof PaymentCreditCardComponent ||
      tab instanceof PaymentCreditCardNuveiComponent ||
      tab instanceof PaymentCreditCardNmiComponent
    ) {
      switch (this.paymentMethod) {
        case PaymentMethod.PAY_PAL:
          this.tabIndex += 1;
          if (
            this.successfulMessage !== SuccessfulMessage.ERROR_DOUBLE_PAYMENT
          ) {
            this.successfulMessage = SuccessfulMessage.PAY_BY_PAY_PAL;
          }
          break;
        case PaymentMethod.WALLET:
        case PaymentMethod.USER_CREDITS:
          this.tabIndex += 1;
          break;
        default:
          break;
      }
    }
  }
}
