<mat-card-title class="dialog-title">
  <span>&nbsp;</span>
  <img
    *ngIf="showIcon"
    [ngClass]="'logo-desktop display'"
    [src]="logoIcon"
    alt="logo"
  />
  <span>
    <span *ngIf="showClose" class="dialog-close" matDialogClose>
      <img [src]="closeIcon" alt="close" />
    </span>
  </span>
</mat-card-title>
