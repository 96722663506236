<lib-modal-window [isVisible]="show" (isClose)="close()">
  <form nz-form class="primary-form" [formGroup]="form" (ngSubmit)="submit()">
    <h1 id="title">{{ 'contact-us' | translate }}</h1>
    <p id="description">
      {{ 'contact-us-text' | translate }}
    </p>

    <nz-form-item>
      <nz-form-control [nzErrorTip]="errors.name">
        <input
          nz-input
          name="name"
          type="name"
          id="name"
          class="primary-input"
          formControlName="name"
          placeholder="Name"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzErrorTip]="errors.email">
        <input
          nz-input
          name="email"
          type="email"
          id="email"
          class="primary-input"
          formControlName="email"
          placeholder="Email"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzErrorTip]="errors.message">
        <textarea
          name="message"
          id="message"
          class="primary-textarea primary-input"
          cols="30"
          rows="10"
          placeholder="Message"
          formControlName="message"
        ></textarea>
      </nz-form-control>
    </nz-form-item>

    <button class="primary-btn">{{ 'send-message' | translate }}</button>
  </form>
</lib-modal-window>

<notifier-container></notifier-container>
