<form
  nz-form
  class="primary-form sign-up-form"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="handleSubmit()"
>
  <h2 class="title">{{ 'enter-personal' | translate }}</h2>
  <div class="primary-flex-start login">
    <p class="info text">{{ 'already-have' | translate }}</p>
    <button
      type="button"
      class="primary-invisible text info-btn main-color"
      (click)="login()"
    >
      {{ 'log-in' | translate }}
    </button>
  </div>

  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.firstName">
      <input
        nz-input
        type="text"
        name="firstName"
        id="firstName"
        class="primary-input"
        formControlName="firstName"
        placeholder="First name"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.lastName">
      <input
        nz-input
        type="text"
        name="lastName"
        id="lastName"
        class="primary-input"
        formControlName="lastName"
        placeholder="Last name"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.email">
      <input
        nz-input
        name="email"
        type="email"
        id="email"
        class="primary-input"
        formControlName="email"
        placeholder="Email"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control
      [nzErrorTip]="errors.phoneNumber"
      style="position: relative"
    >
      <ngx-intl-tel-input
        [cssClass]="'custom'"
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="true"
        [searchCountryFlag]="true"
        [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.UnitedStates"
        [maxLength]="'15'"
        [phoneValidation]="true"
        [separateDialCode]="separateDialCode"
        [numberFormat]="PhoneNumberFormat.National"
        name="phone"
        formControlName="phoneNumber"
      >
      </ngx-intl-tel-input>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.password">
      <div class="password-box">
        <input
          nz-input
          [type]="handlePasswordType(showPassword)"
          name="password"
          id="password"
          class="primary-input"
          formControlName="password"
          placeholder="Password"
        />
        <div class="indicators">
          <img *ngIf="showError" src="assets/password_kit/cancel.svg" />
          <img
            *ngIf="!showError && submited"
            src="assets/password_kit/success.svg"
          />
          <img
            class="show-switch"
            (click)="showPassword = !showPassword"
            [src]="passwordIconUrl(showPassword)"
          />
        </div>
      </div>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.confirmPassword">
      <div class="password-box">
        <input
          nz-input
          [type]="handlePasswordType(showConfirmPassword)"
          name="confirmPassword"
          id="confirmPassword"
          class="primary-input"
          formControlName="confirmPassword"
          placeholder="Confirm Password"
        />
        <div class="indicators">
          <img
            class="show-switch"
            (click)="showConfirmPassword = !showConfirmPassword"
            [src]="passwordIconUrl(showConfirmPassword)"
          />
        </div>
      </div>
    </nz-form-control>
  </nz-form-item>

  <p class="conditions">
    {{ 'pass-valid' | translate }}
  </p>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.acceptTermsOfService">
      <div class="checkbox-block">
        <input
          nz-input
          type="checkbox"
          name="acceptTermsOfService"
          id="acceptTermsOfService"
          class="primary-input"
          formControlName="acceptTermsOfService"
        />
        <label
          >{{ 'i-have-read-and-agree-to-the' | translate }}
          <a target="_blank" routerLink="/terms">{{
            'terms-of-service' | translate
          }}</a></label
        >
      </div>
    </nz-form-control>
  </nz-form-item>

  <div class="my-2" style="position: relative">
    <re-captcha
      style="z-index: 99999"
      (resolved)="resolved($event)"
      (error)="errored($event)"
      errorMode="handled"
      [siteKey]="environmentCaptchaToken"
    ></re-captcha>
  </div>

  <button type="submit" class="primary-btn sign-up">
    {{ 'next-up' | translate }}
  </button>

  <div class="primary-flex-center margin-top">
    <p class="info text">{{ 'did-not-receive' | translate }}</p>
    <button
      type="button"
      class="primary-invisible text info-btn main-color"
      (click)="showResendConfirmEmail()"
    >
      {{ 'resend' | translate }}
    </button>
  </div>
</form>
