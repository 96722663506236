<footer id="support" class="footer container">
  <div class="contacts">
    <div class="wrapper">
      <div class="logo-wrapper">
        <img
          src="./assets/desktop-logo.svg"
          alt="logo"
          class="logo"
          (click)="onScrollToUp()"
          width="125"
          height="28"
        />
      </div>
      <div class="address">
        <a
          href="https://goo.gl/maps/RHmf6HBPBEAcnhhc6"
          target="_blank"
          class="address-content"
          >Take Profit Trader LLC<span class="address-br"></span>1317 Edgewater
          Drive #3797 <span class="address-br"></span>Orlando, FL 32804</a
        >
      </div>
      <div class="about">
        <a class="about-content" [routerLink]="'/about-us'">{{
          'about-us' | translate
        }}</a>
        <p class="about-content" (click)="toggleShowContactUs()">
          {{ 'contact-us' | translate }}
        </p>
        <a
          class="about-content"
          target="_blank"
          href="https://takeprofittraderhelp.zendesk.com/hc/en-us"
          >{{ 'knowledge-base' | translate }}</a
        >
      </div>
    </div>
    <div class="networks" *ngIf="false">
      <div *ngFor="let n of networks" class="image-container">
        <a [routerLink]="n.link" class="networks-link" target="_blank">
          <i class="fab {{ n.source }}"></i>
        </a>
      </div>
    </div>
  </div>
  <div *ngFor="let d of disclaimer" class="disclaimer">
    <h2 class="disclaimer-title">{{ d.title | translate }}</h2>
    <p
      *ngFor="let p of d.paragraphs"
      class="disclaimer-content"
      [innerHTML]="p | translate"
    ></p>
  </div>
  <div class="copyright">
    <div class="copyright-wrapper">
      <p class="copyright-content">
        &#169; {{ 'copyright' | translate }}.
        <span class="copyright-br"></span> {{ 'all-rights' | translate }}.
      </p>
      <a target="_blank" routerLink="/terms"
        ><span class="copyright-terms" *ngIf="isShowTerms">{{
          'terms-of-service' | translate
        }}</span></a
      >
    </div>
  </div>
</footer>

<lib-contact-us
  [show]="showContactUs"
  (nativeOpen)="toggleShowContactUs()"
></lib-contact-us>
