import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmEmailService } from '../confirm-email.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ErrorFormService,
  ErrorHttpService,
  ErrorsMap,
  IErrorMessage,
  NotificationService,
  TypeMessage,
} from 'repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopUpSize } from 'common-pop-up';

@UntilDestroy()
@Component({
  selector: 'lib-resend-confirm-email',
  templateUrl: './resend-confirm-email.component.html',
  styleUrls: ['./resend-confirm-email.component.scss'],
})
export class ResendConfirmEmailComponent {
  @Input()
  public set isShow(isShow: boolean) {
    if (this._isShow === isShow) {
      return;
    }

    this._isShow = isShow;
    this.isShowChange.emit(isShow);
  }

  public get isShow(): boolean {
    return this._isShow;
  }

  @Output()
  public readonly isShowChange = new EventEmitter<boolean>();

  @Input()
  public set isExpired(isExpired: boolean) {
    this._isExpired = isExpired;
  }

  public get isExpired(): boolean {
    return this._isExpired;
  }

  public readonly PopUpSize = PopUpSize;
  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    email: {
      required: 'Email is required',
      email: 'Email is required',
    },
  };

  public readonly form: FormGroup;

  public errors: ErrorsMap<string> = {};

  private _isShow = false;
  private _isExpired = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly notification: NotificationService,
    private readonly errorFormService: ErrorFormService,
    private readonly errorHttpService: ErrorHttpService,
    private readonly confirmEmailService: ConfirmEmailService
  ) {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  public submit(): void {
    const form = this.form;
    const value = form.value;

    this.errors = this.errorFormService.verifyError(form, this.errorMessages);

    if (!form.valid || form.disabled) {
      return;
    }

    this.resendConfirmEmail(value.email);
  }

  close(): void {
    this.isShow = false;
  }

  private resendConfirmEmail(email: string): void {
    this.confirmEmailService
      .resendConfirmEmail(email)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.isShow = false;
          this.notification.showBlankNotification(
            'Confirmation email sent successfully! Please, check your mail!',
            TypeMessage.SUCCESS
          );
        },
        (error) => this.errorHttpService.showMessage(error)
      );
  }
}
